<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-if="checkPermission([consts.CUSTOMER_LIST_VIEW])"
              :to="{
                name: 'CustomerList',
              }"
              >會員管理</b-breadcrumb-item
            >

            <b-breadcrumb-item
              :to="{
                name: 'CustomerDetailView',
                params: { customerID: this.customer.id },
              }"
              >會員資料</b-breadcrumb-item
            >
            <b-breadcrumb-item active>會員資料編輯 - {{ customer.name }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-2 align-items-center">
          <div class="col-12 col-xl-6">
            <h4 class="font-weight-bold">會員資料編輯 - {{ customer.name }}</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group :disabled="readonly">
              <div class="mb-2">
                來源通路: {{ customer.merchant.type_name }}({{ customer.merchant.name }})
              </div>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('name', '名稱')"
                v-if="hiddenFields('name')"
              >
                <b-form-input v-model="customer.name" :disabled="readonlyFields('name')"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('outer_code', '識別編號')"
                v-if="hiddenFields('outer_code')"
              >
                <b-form-input v-model="customer.outer_code"  :disabled="readonlyFields('outer_code')"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('mobile_phone', '手機')"
                v-if="hiddenFields('mobile_phone')"
              >
                <b-form-input v-model="customer.mobile_phone" :disabled="readonlyFields('mobile_phone')"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('phone_verified_at', '手機驗證時間')"
                v-if="hiddenFields('phone_verified_at')"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="選擇日期"
                      v-model="customer.phone_verified_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      clear-button
                      :disabled="readonlyFields('phone_verified_at')"
                    ></datepicker>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      placeholder="選擇時間"
                      v-model="customer.phone_verified_at.time"
                      :disabled="readonlyFields('phone_verified_at')"
                    ></vue-timepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('email', 'E-mail')"
                v-if="hiddenFields('email')"
              >
                <b-form-input v-model="customer.email" :disabled="readonlyFields('email')"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('sexual', '性別')"
                v-if="hiddenFields('sexual')"
              >
                <b-form-radio-group
                  v-model="customer.sexual"
                  :options="sexualOptions"
                  class="mb-3"
                  value-field="value"
                  text-field="text"
                  :disabled="readonlyFields('sexual')"
                ></b-form-radio-group>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('birthday', '生日')"
                v-if="hiddenFields('birthday')"
              >
                <datepicker
                  placeholder="選擇日期"
                  v-model="birthday"
                  bootstrap-styling
                  format="yyyy-MM-dd"
                  :language="zh"
                  clear-button
                  :disabled="readonlyFields('birthday')"
                ></datepicker>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('created_at', '加入時間')"
                v-if="hiddenFields('created_at')"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="選擇日期"
                      v-model="customer.created_at.date"
                      bootstrap-styling
                      :clear-button="!isLineCustomer"
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled="readonlyFields('created_at')"
                    ></datepicker>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      placeholder="選擇時間"
                      v-model="customer.created_at.time"
                      :disabled="readonlyFields('created_at')"
                    ></vue-timepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('accept_term_at', '條款接受時間')"
                v-if="hiddenFields('accept_term_at')"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="選擇日期"
                      v-model="customer.accept_term_at.date"
                      bootstrap-styling
                      clear-button
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      placeholder="選擇時間"
                      v-model="customer.accept_term_at.time"
                    ></vue-timepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                content-cols="12"
                content-cols-lg="6"
                :label="displayName('registered_at', '註冊時間')"
                v-if="hiddenFields('registered_at')"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="選擇日期"
                      v-model="customer.registered_at.date"
                      bootstrap-styling
                      clear-button
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      placeholder="選擇時間"
                      v-model="customer.registered_at.time"
                    ></vue-timepicker>
                  </div>
                </div>
              </b-form-group>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :disabled="readonly">
              <template v-for="(field, index) in fields">
              <b-form-group
                :key="'field' + index"
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                :label="field.title"
                :description="field.memo"
                v-if="!field.isHidden"
              >
                <b-form-input
                  v-if="field.type === 'text'"
                  v-model="customer.profiles[field.matched_key]"
                  :disabled="!field.can_edit"
                ></b-form-input>

                <b-form-select
                  v-if="field.type === 'select'"
                  v-model="customer.profiles[field.matched_key]"
                  :options="field.options"
                  :disabled="!field.can_edit"
                ></b-form-select>

                <b-form-radio-group
                  v-if="field.type === 'radio'"
                  v-model="customer.profiles[field.matched_key]"
                  :options="field.options"
                  :disabled="!field.can_edit"
                ></b-form-radio-group>

                <b-form-checkbox-group
                  v-if="field.type === 'checkbox'"
                  v-model="customer.profiles[field.matched_key]"
                  :options="field.options"
                  :disabled="!field.can_edit"
                ></b-form-checkbox-group>
              </b-form-group>
              </template>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="!readonly" style="margin-top: 80px">
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button variant="success" @click="handleSubmit">
            <template>確認編輯</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>z

<script>
import customerApi from "@/apis/customer";
import Datepicker from "vuejs-datepicker";
import {zh} from "vuejs-datepicker/dist/locale";
import { mapState, mapGetters } from "vuex";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import {format, set, formatISO} from "date-fns";
import _ from 'lodash';
import { isValidJsonArray } from "@/utils/jsonHelper"
import VueTimepicker from "vue2-timepicker";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'

export default {
  components: { Datepicker, VueTimepicker },
  data() {
    return {
      zh,
      showLoading: false,
      customer: {
        profiles: {},
        merchant: {},
        created_at: {},
        phone_verified_at: {},
        registered_at: {},
        accept_term_at: {},
      },
      profileMappings: [],
      birthday: null,
      sexualOptions: [
        { text: '男', value: 'male' },
        { text: '女', value: 'female' },
        { text: '未設定', value: null },
      ],
      phoneVerifiedOptions: [
        { text: '是', value: true },
        { text: '否', value: false },
      ],
      fields: [],
      consts,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    readonly() {
      return this.$route.name === "customerDetail"
    },
    isEdit() {
      return this.$route.name === "customerEdit"
    },
    isCreate() {
      return this.$route.name === "customerCreate"
    },
    isLineCustomer() {
      return this.customer.merchant?.type === 'LINE'
    },
    customerFields() {
      const fields = this.getModuleConfig('customer', 'customer_fields')
      if (fields) {
        return fields.find((f) => f.merchant_type.toUpperCase() == this.customer.merchant.type)?.member_fields ?? []
      } else {
        return false
      }
    },
  },
  mounted() {
    this.getCustomer()
  },
  methods: {
    format,
    isValidJsonArray,
    readonlyFields(key) {
      if (this.customerFields) {
        const canEdit = this.customerFields.find(field => field.matched_key == key)?.can_edit ?? true
        if (!canEdit) {
          return !canEdit
        }
      }
      if (this.isLineCustomer && (key == 'outer_code' || key == 'name')) {
        return true
      }
      return false
    },
    displayName(key, defaultText) {
      if (this.customerFields) {
        const text = this.customerFields.find(field => field.matched_key == key)?.title
        if (text) {
          return text
        }
      }
      return defaultText
    },
    hiddenFields(key) {
      if (this.customerFields) {
        const isHidden = this.customerFields.find(field => field.matched_key == key)?.is_hidden
        if (isHidden) {
          return !isHidden
        }
      }
      return true
    },
    async getCustomer() {
      try {
        const { data } = await customerApi.getCustomer(
          this.$route.params.customerID
        );
        this.customer = {
          ...data,
          join_datetime:
            data.join_datetime &&
            format(new Date(data.join_datetime), "yyyy-MM-dd HH:mm"),
          leave_datetime:
            data.leave_datetime &&
            format(new Date(data.leave_datetime), "yyyy-MM-dd HH:mm"),
          updated_at: format(new Date(data.updated_at), "yyyy-MM-dd HH:mm"),
          created_at: data.created_at ? this.formatToDateObject(data.created_at) : {},
          phone_verified_at: data.phone_verified_at ? this.formatToDateObject(data.phone_verified_at) : {},
          registered_at: data.registered_at ? this.formatToDateObject(data.registered_at) : {},
          accept_term_at: data.accept_term_at ? this.formatToDateObject(data.accept_term_at) : {},
        };
        this.profileMappings = data.meta.profile_mappings;

        // 依照 module_config 的 member_fields 來排序
        let fields = data.meta.module_config?.member_fields ?? [];
        this.fields = _.sortBy(fields, "order")

        // 處理是checkbox類別的 內容要還原回json
        Object.keys(this.customer.profiles).forEach((key) => {
          const value = this.customer.profiles[key];
          // 在這裡處理屬性和值
          if (this.isValidJsonArray(value)) {
            this.customer.profiles[key] = JSON.parse(value)
          }
        });

        Object.keys(this.fields).forEach((key) => {
          this.fields[key]['matched_key'] = this.fields[key]['matched_key'].replace(/extra./g,'');
        });

        if (this.customer.profiles.constructor !== Object ) {
          this.customer.profiles = {}
        }
        // 處理幾個欄位的值
        this.birthday = data.birthday ? new Date(data.birthday) : null;
        this.text = JSON.stringify(data.data);
      } catch (e) {
        console.log(e);
      }
    },
    formatToDateObject(datetime) {
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
    handleCancel() {
      this.$router.push({
        name: 'CustomerDetailView',
        params: { customerID: this.customer.id },
      });
    },
    formatToDatetimeString(datetime) {
      return formatISO(
        set(new Date(datetime.date), {
            hours: Number(datetime.time.HH),
            minutes: Number(datetime.time.mm),
            seconds: 0,
          },
        )
      )
    },
    async handleSubmit() {
      this.showLoading = true;
      this.customer.birthday = this.birthday ? format(new Date(this.birthday), "yyyy-MM-dd") : null;
      this.customer.phone_verified_at = this.customer.phone_verified_at.date ? this.formatToDatetimeString(this.customer.phone_verified_at) : null;
      this.customer.registered_at = this.customer.registered_at.date ? this.formatToDatetimeString(this.customer.registered_at) : null;
      this.customer.accept_term_at = this.customer.accept_term_at.date ? this.formatToDatetimeString(this.customer.accept_term_at) : null;
      this.customer.created_at = this.customer.created_at.date ? this.formatToDatetimeString(this.customer.created_at) : null;
      try {
        await customerApi.storeCustomer(this.customer.id, this.customer);
        // await customerApi.storeCustomerProfiles(this.customer.id, this.customer.profiles);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({
              name: 'CustomerDetailView',
              params: { customerID: this.customer.id },
            });
          });
      } catch (error) {
        console.error(error);
      }

      this.showLoading = false;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
  },
};
</script>
